import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
        path: "/login",
        component: () => import("./components/login-page.vue")
    },
    {
        path: "/maintenance",
        component: () => import("./components/maintenance-page.vue")
    },
    {
        path: "",
        redirect: "/login"
    }
];

export default createRouter({
    routes,
    history: createWebHashHistory()
});
